@tailwind base;
@tailwind components;
@tailwind utilities;

#pagefind-container {
  .pagefind-ui__drawer {
    @apply absolute;
    @apply bg-white;
  }
}

@layer utilities {
  .grid-layout {
    @apply grid grid-cols-4 gap-4 m-4;
    @apply md:grid-cols-6 md:gap-6 md:m-6;
    @apply lg:grid-cols-12 lg:m-12;
  }

  .grid-layout > * {
    @apply col-span-full;
  }
}
